import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const TeamPage = ({
  data: {
    site
  },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>Team — {site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <div className="hero-header page-header">
        <p>NextStepHQ is founded by veteran entrepreneurs Ravi Vyas & Abhishek Nandi. They have previously founded PureMetrics Inc and Odiocast, which got acquired by YourStory Media. Together they have more than 2 decades of experience, a large part of which has been in the Indian startup ecosystem.</p>
      </div>
      <div className="two-grids">
        <div>
        <img className="avatar" src="/assets/ravi-vyas.jpeg" alt="Ravi Vyas" title="Ravi Vyas"/>
          <h2 className="founder-name">Ravi Vyas</h2>
          <h5 className="tagline">Founder &amp; CEO</h5>
          <h5 className="tagline">Man of Many Hats, working on acquiring for More</h5>
          <div className="container-h">
            <a href="http://linkedin.com/in/ravivyas" target="_blank"><div className="ic-linkedin social-icon"></div></a>
            <a href="https://www.ravivyas.com" target="_blank"><div className="ic-webpage social-icon"></div></a>
          </div>
        </div>
        <div>
          <img className="avatar" src="/assets/abhishek-nandi.jpeg" alt="Abhishek Nandi" title="Abhishek Nandi"/>
          <h2 className="founder-name">Abhishek Nandi</h2>
          <h5 className="tagline">Founder &amp; CTO</h5>
          <h5 className="tagline">printf(“Hello World!!”);</h5>
          <div className="container-h">
            <a href="https://www.linkedin.com/in/nandiabhishek/" target="_blank"><div className="ic-linkedin social-icon"></div></a>
            <a href="https://www.abhisheknandi.com" target="_blank"><div className="ic-webpage social-icon"></div></a>
          </div>
          
        </div>
      </div>
      </Layout>
   )
  }

export default TeamPage
export const pageQuery = graphql`
  query TeamPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`